import styled from "styled-components";

const PageTitleSection = styled.div`
    display: flex;
    flex-direction: column;

    @media (max-width: 768px) {
        display: none;
    }
    
    h3 {
        margin: 10px 0;
        font-weight: bold;
    }
`;

export default PageTitleSection;