import React from "react";
import PageWrapper from "../components/PageWrapper";
import PageTitleSection from "../components/PageTitleSection";
import PageContentSection from "../components/PageContentSection";

function ContactPage() {
    return (
        <PageWrapper>
            <PageTitleSection>
                <h3>Contact</h3>
            </PageTitleSection>
            <PageContentSection>
                <h2>How to contact Tweed Consulting:</h2>
                <p>Daniel Svanström, Founder & Partner</p>
                <table>
                    <tbody>
                    <tr>
                        <th>Mail</th>
                        <td>
                            <a href="mailto:daniel.svanstrom@tweedconsulting.se">
                                daniel.svanstrom@tweedconsulting.se
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <th>Mobile</th>
                        <td>+46 70 270 93 00</td>
                    </tr>
                    <tr>
                        <th>Address</th>
                        <td>Lillängsgatan 10, S-168 58 Bromma, Sweden</td>
                    </tr>
                    </tbody>
                </table>
                <hr/>
                <figure>
                    <img src="/images/StockSnap_2MMP3QHH4A-e1495628566560.jpg"
                         alt="Cabin"/>
                    <figcaption>We are not likely in the office but at a customers' site (wonder why...). Contact us via
                        mail or give us a call!
                    </figcaption>
                </figure>
            </PageContentSection>
        </PageWrapper>
    )
        ;
}

export default ContactPage;