import React from "react";
import PageWrapper from "../components/PageWrapper";
import PageTitleSection from "../components/PageTitleSection";
import PageContentSection from "../components/PageContentSection";

function HomePage() {
    return (
        <PageWrapper>
            <PageTitleSection>
                <h3>Home</h3>
            </PageTitleSection>
            <PageContentSection>
                <h2>Welcome to Tweed Consulting - tuning your
                    Infor M3 ERP system</h2>
                <p>Welcome to Tweed Consulting - your solid but casual M3 experts. Founded in 2016 but with M3
                    experience dating back to the start of the millennium.</p>
                <p>A small and personal consultancy firm, but with a network of experts across the globe.</p>
                <p>Tweed Consulting can provide advisory services in solution design, as well as detailed configuration
                    and modification recommendations combined with an independent view on both project staffing and
                    system functionality.</p>
                <hr/>
                <figure>
                    <img src="/images/014.jpg" alt="Tweed Consulting"/>
                    <figcaption>Four examples of successful problem solving!</figcaption>
                </figure>
            </PageContentSection>
        </PageWrapper>
    );
}

export default HomePage;