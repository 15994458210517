import styled from 'styled-components';
import ContentWrapper from "./ContentWrapper";

const FooterContainer = styled.footer`
    border-top: 1px solid lightgray;
    padding: 30px 0;
    position: relative; /* Ensure the container is positioned relative */
`;

const FooterContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: left;
    position: relative;
`;

const ContactHeader = styled.h3`
    margin: 0;
    font-size: 1.1rem;
    text-align: left;
    padding-bottom: 10px;
`;

const ContactList = styled.ul`
    display: flex;
    flex-direction: column;
    justify-content: left;
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: left;
`;

const ContactItem = styled.li`
    font-size: 0.9rem;

    &:not(:last-child) {
        margin-bottom: 8px;
    }

    a {
        text-decoration: none;
    }
`

const Copyright = styled.p`
    color: gray;
    font-size: 0.9rem;
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 0;

    @media (max-width: 768px) {
        position: relative;
        margin-top: 20px;
    }
`

export const Footer = () => {
    return (
        <FooterContainer>
            <ContentWrapper>
                <FooterContent>
                    <ContactHeader>Contact us:</ContactHeader>
                    <ContactList>
                        <ContactItem><a href="mailto:daniel.svanstrom@tweedconsulting.se">
                            daniel.svanstrom@tweedconsulting.se</a>
                        </ContactItem>
                        <ContactItem>+46 70 270 93 00</ContactItem>
                        <ContactItem>Lillängsgatan 10, S-168 58 Bromma, Sweden</ContactItem>
                    </ContactList>
                    <Copyright>&copy; Tweed Consulting AB</Copyright>
                </FooterContent>
            </ContentWrapper>
        </FooterContainer>
    )
};

export default Footer;