import styled from "styled-components";

export const ContentWrapper = styled.div`
    max-width: 1000px;
    margin: 0 auto;
    padding: 0 20px;

    @media (min-width: 1920px) {
        max-width: 1400px;
    }

    @media (min-width: 2560px) {
        max-width: 1600px;
    }
`;

export default ContentWrapper;