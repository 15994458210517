import styled from "styled-components";

const PageWrapper = styled.div`
    display: grid;
    grid-template-columns: ${({ columns }) => columns || "3fr 4fr"};
    align-items: start;
    gap: 20px;
    padding: 50px 0 80px;
    margin: 0;
    

    @media (max-width: 768px) {
        padding: 30px 0 80px;
        grid-template-columns: 1fr;
    }
`;

export default PageWrapper;