import styled from 'styled-components';
import ContentWrapper from "./ContentWrapper";
import {NavLink} from 'react-router-dom';

const NavBarContainer = styled.nav`
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 30px 0;
    background-color: white;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 10;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

    @media (max-width: 768px) {
        flex-direction: column;
        padding: 10px 0;
    }
`;

const NavBarMenu = styled.ul`
    display: flex;
    justify-content: left;
    list-style: none;
    margin: 0;
    padding: 0;

    @media (max-width: 768px) {
        flex-direction: column;
        width: 100%;
    }
`;

const MenuItem = styled.li`
    position: relative;
    padding: 0 25px;

    &:first-child {
        padding-left: 0;
    }

    &:not(:last-child) {
        border-right: 1px solid lightgray;

        @media (max-width: 768px) {
            border-right: none;
        }
    }

    @media (max-width: 768px) {
        padding: 5px 0;
    }
`;

const MenuLink = styled(NavLink)`
    color: black;
    text-decoration: none;
    font-size: 1.0rem;
    display: block;
    font-weight: bold;

    &:hover {
        text-decoration: underline;
    }

    &.active {
        color: gray;
    }
`;

/*
<MenuItem>
    <MenuLink to="/news">News</MenuLink>
</MenuItem>
 */
export const NavBar = () => {
    return (
        <NavBarContainer>
            <ContentWrapper>
                <NavBarMenu>
                    <MenuItem>
                        <MenuLink to="/" end>Home</MenuLink>
                    </MenuItem>

                    <MenuItem>
                        <MenuLink to="/consultancy-services">Consultancy Services</MenuLink>
                    </MenuItem>
                    <MenuItem>
                        <MenuLink to="/about">About</MenuLink>
                    </MenuItem>
                    <MenuItem>
                        <MenuLink to="/partners">Partners</MenuLink>
                    </MenuItem>
                    <MenuItem>
                        <MenuLink to="/contact">Contact</MenuLink>
                    </MenuItem>
                </NavBarMenu>
            </ContentWrapper>
        </NavBarContainer>
    );
};

export default NavBar;
