import { styled, createGlobalStyle } from 'styled-components';
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Banner from "./components/Banner";
import NavBar from "./components/NavBar";
import ContentWrapper from "./components/ContentWrapper";
import Footer from "./components/Footer";
import ScrollToTop from "./components/ScrollToTop";

import HomePage from "./pages/HomePage";
import ConsultancyServicesPage from "./pages/ConsultancyServicesPage";
import AboutPage from "./pages/AboutPage";
import PartnersPage from "./pages/PartnersPage";
import ContactPage from "./pages/ContactPage";

const GlobalStyle = createGlobalStyle`

    @font-face {
        font-family: 'Montserrat';
        src: url('/fonts/Montserrat-VariableFont_wght.ttf') format('truetype');
    }
    
    * {
        box-sizing: border-box;
    }

    body {
        margin: 0;
        background-color: white;
        font-family: 'Montserrat', Arial, sans-serif;
    }
`;

function App() {
    return (
        <BrowserRouter>
            <ScrollToTop/>
            <GlobalStyle/>
            <AppContainer>
                <Banner/>
                <StickyContainer>
                    <NavBar/>
                    <ContentWrapper>
                        <Routes>
                            <Route path="/" element={<HomePage/>}/>
                            <Route path="/consultancy-services" element={<ConsultancyServicesPage/>}/>
                            <Route path="/about" element={<AboutPage/>}/>
                            <Route path="/partners" element={<PartnersPage/>}/>
                            <Route path="/contact" element={<ContactPage/>}/>
                        </Routes>
                    </ContentWrapper>
                </StickyContainer>
                <Footer/>
            </AppContainer>
        </BrowserRouter>
    );
}

const AppContainer = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    min-width: 300px;
    margin: 0;
    padding: 0;
`;

const StickyContainer = styled.div`
    flex-grow: 1;
`;

export default App;
