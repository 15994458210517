import React from "react";
import PageWrapper from "../components/PageWrapper";
import PageTitleSection from "../components/PageTitleSection";
import PageContentSection from "../components/PageContentSection";

function PartnersPage() {
    return (
        <PageWrapper>
            <PageTitleSection>
                <h3>Partners</h3>
            </PageTitleSection>
            <PageContentSection>
                <p>Tweed Consulting is a partner of <b>Infor</b> dating back to 2017.</p>
                <p>During the years Tweed Consulting has partnered with M3 implementation companies like <b>Navcite</b>
                    , <b>Columbus</b>, <b>Leanswift</b> and <b>Anthesis</b> for supporting in pre-studies and
                    implementation projects.</p>
            </PageContentSection>
        </PageWrapper>
    );
}

export default PartnersPage;