import styled from 'styled-components';
import ContentWrapper from "./ContentWrapper";

const bannerImage = '/images/039.jpg'

const BannerContainer = styled.header`
    background: url(${bannerImage}) no-repeat center center/cover;
    color: white;
    text-align: left;
    padding: 60px 0;
    
    @media (max-width: 768px) {
        padding: 30px 0;
    }
`;

const BannerTitle = styled.h1`
    margin: 0 0 15px;
    font-size: 2.5rem;
    font-weight: bold;
    text-shadow: 0 0 0.25rem rgba(0, 0, 0, 1.0);
`;

const BannerSubTitle = styled.p`
    margin: 0;
    font-size: 1.3rem;
    font-style: italic;
    text-shadow: 0 0 0.2rem rgba(0, 0, 0, 1.0);
`;

export const Banner = () => {
    return (
        <BannerContainer>
            <ContentWrapper>
                <BannerTitle>TWEED CONSULTING AB</BannerTitle>
                <BannerSubTitle>Your independent Infor M3 consultant</BannerSubTitle>
            </ContentWrapper>
        </BannerContainer>
    )
};

export default Banner;