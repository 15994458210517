import styled from "styled-components";

const PageContentSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;


    h1, h2, h3, p {
        margin: 10px 0;
    }

    h2 {
        color: dimgray;
        font-weight: normal;
    }

    p {
        text-align: justify;
        font-size: 1.1rem;
        line-height: 1.7rem;
    }

    figure {
        max-width: 450px;
        width: 100%;
        height: auto;
        margin: 10px 0 0;
    }

    img {
        width: 100%;
        height: auto;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    }

    figcaption {
        margin: 10px 0;
        font-size: 1rem;
        color: dimgray;
        font-style: italic;
    }

    ul, ol {
        font-size: 1.1rem;
        line-height: 1.7rem;
        padding-left: 0;
        margin-left: 1rem;
        margin-top: 0.5rem;
    }

    a {
        text-decoration: none;
    }

    hr {
        margin: 50px 0;
        border: 0;
        border-top: 1px solid lightgray;
    }

    table {
        width: 100%;
        font-size: 0.9rem;
    }

    th, td {
        text-align: left;
        padding: 10px 0;
        margin: 0;
    }

    th {
        font-weight: bold;
    }

    td {
        text-align: right; /* Aligns the right column text to the right */
    }
`;

export default PageContentSection;