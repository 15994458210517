import React from "react";
import PageWrapper from "../components/PageWrapper";
import PageTitleSection from "../components/PageTitleSection";
import PageContentSection from "../components/PageContentSection";

function ConsultancyServicesPage() {
    return (
        <PageWrapper>
            <PageTitleSection>
                <h3>Consultancy Services</h3>
            </PageTitleSection>
            <PageContentSection>
                <h2>ERP Consultancy services in various systems, but with focus on Infor M3</h2>
                <p> What can we help you with? Our consultancy services include multiple areas with focus on:</p>
                <ul>
                    <li>Implementing/upgrading MOVEX/M3</li>
                    <li>Business process review and Infor M3 Best Practice adoption</li>
                    <li>Process enhancement and optimizing system utilization.</li>
                    <li>Review of customer modifications and replacing them with standard M3 Cloud functionality.</li>
                </ul>
                <hr/>
                <h2>Needing more assistance?</h2>
                <p>Tweed Consulting has partnerships with companies with experienced consultants, who can assist you in
                    the following areas:</p>
                <ul>
                    <li>Business Consulting</li>
                    <li>Support Implementation projects</li>
                    <li>Support upgrade projects</li>
                    <li>Configuration Management</li>
                    <li>Application support</li>
                    <li>Test Management</li>
                    <li>Pre-studies</li>
                    <li>Project Management</li>
                    <li>Integrations</li>
                    <li>Data migration</li>
                    <li>Modifications</li>
                    <li>MeC</li>
                    <li>Movex - RPG, including programming</li>
                </ul>
            </PageContentSection>
        </PageWrapper>
    );
}

export default ConsultancyServicesPage;