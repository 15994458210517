import React from "react";
import PageWrapper from "../components/PageWrapper";
import PageTitleSection from "../components/PageTitleSection";
import PageContentSection from "../components/PageContentSection";

function AboutPage() {
    return (
        <PageWrapper>
            <PageTitleSection>
                <h3>About</h3>
            </PageTitleSection>
            <PageContentSection>
                <p> The founder, Daniel, is an experienced IT consultant with a background in finance and business
                    administration.</p>
                <p>After graduating from the University in Linköping, Master of Economics, he has worked as CFO,
                    Financial Manager, in Swedish and Nordic companies for a total of 7 years before becoming a
                    consultant working with ERP systems. During his now more than 20 years long consultant career, he
                    has specialized in Financial Management in connection with: Material management, Sales, Production
                    and Maintenance including system interface solutions.</p>
                <p> Daniel is a specialist in M3, but he has also experience in working with Prosit/Raindance, ASW, AMOS
                    and BPCS.</p>
                <p>Below are examples of clients in projects:</p>
                <ul>
                    <li>Kopparbergs Bryggerier</li>
                    <li>Lantmännen</li>
                    <li>Elektroskandia (Norway)</li>
                    <li>Plantagen</li>
                    <li>Spira Blommor</li>
                    <li>Altus Intervention (Norway)</li>
                    <li>Fuller's (UK)</li>
                    <li>Custom Truck (US)</li>
                    <li>Inter Plastic (US)</li>
                    <li>Scandinavian Airlines</li>
                    <li>Euromaint</li>
                    <li>Elkem AS (Norway)</li>
                    <li>Ambu A/S (Denmark)</li>
                    <li>CHC, Canadian Helicopter Company</li>
                    <li>Arla</li>
                    <li>Finnveden Bulten</li>
                    <li>Braathens Technical Services (Norway)</li>
                    <li>Micronic Mydata</li>
                    <li>Recipharm</li>
                </ul>
            </PageContentSection>
        </PageWrapper>
    );
}

export default AboutPage;